/* .outer-container {
    border-bottom: 2px solid var(--aqua);
} */

.main-container {
    font-size: 24px;
    max-width: 200px;

    border: none;
    background-color: transparent;

    /* font-family: inherit; */
    font-family: var(--roboto);
}

.main-container:focus {
    outline: none;
}

.lower-border {
    width: 100%;
    border-bottom: 2px solid;
}