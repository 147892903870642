.actions {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.outer-border {
    border: 1px solid var(--light);
    border-radius: 8px;
}

.confirm {
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    font-size: 22px;
    border-width: 3px;
    border-radius: 8px;
    font-family: inherit;
    cursor: pointer;
    display: block;
    min-height: 58px;
    max-width: 220px;
    line-height: 120%;
    font-family: var(--sexy);
    font-style: italic;
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
}

.confirm:not(:hover) {
    color: white;
}

.confirm:hover {
    background-color: white;
}

.confirm:disabled {
    background-color: var(--pencil);
    border-color: var(--invisible);
    color: var(--invisible);
    cursor: auto;
}
