.outer-container {
    position: relative;

    height: var(--active-topics-height);
    width: 100%;
    z-index: 1;
    /* z-index: 0; */
    pointer-events: none;
}

.main-container {
    pointer-events: all;
    position: absolute;
    top: 0px;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 2px 2px hsl(0deg 0% 0% / 0.45);
    height: var(--active-topics-height);
    width: 100%;

    overflow: hidden;
}


.topics-holder {
    padding: 16px 8px 8px 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    align-content: flex-start;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
}

.topics-holder.with-bar {
    height: calc(100% - 36px);
    padding-top: 0px;
    padding-bottom: 32px;
}

.topics-holder.neutral-topics {
}

.topics-holder.light-topics {
    /* background-color: rgb(255, 255, 255, 0.15); */
}

.main-container.opened {
    /* Invisible by default */
}

.main-container.closed {
    z-index: -1;
    top: calc(-1 * var(--active-topics-height) + var(--top-bar-height));
}

.main-container.close {
    z-index: -1;
    top: calc(-1 * var(--active-topics-height) + var(--top-bar-height));
    animation: hideTopics var(--close-speed, 800);
}

.main-container.open {
    animation: showTopics var(--open-speed, 800);
}

@keyframes hideTopics {
    0% {
        top: 0;
    }

    100% {
        top: calc(-1 * var(--active-topics-height) + var(--top-bar-height));
    }
}

@keyframes showTopics {
    0% {
        top: calc(-1 * var(--active-topics-height) + var(--top-bar-height));
    }

    100% {
        top: 0;
    }
}
