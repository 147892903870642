.hidden-drawer {
    visibility: hidden;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;

    width: 100%;
}

.hidden-drawer.show {
    visibility: visible;
    -webkit-animation: pullIn 0.3s;
    animation: pullIn 0.3s;
}

.hidden-drawer.hide {
    -webkit-animation: pushOut 0.3s;
    animation: pushOut 0.3s;
}

.search-box {
    width: 90%;
    padding-left: 2vh;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.search-icon {
    position: absolute;
    left: 0;
    padding-left: calc(3vh + 5px);
    color: var(--pencil);
    text-shadow: none;
}

.border-box {
    border: 1px solid rgb(1, 1, 1, 0.1);
    border-radius: 50px;
}

.search-input {
    font-family: var(--roboto);

    height: 32px;
    width: 100%;
    border: none;
    border-radius: 50px;
    padding-left: 40px;
}

.cancel-button {
    position: absolute;
    right: 0;
    top: 0;
}

@-webkit-keyframes pullIn {
    from {
        right: calc(var(--currentViewHeight) * -1);
    }
    to {
        right: 0;
    }
}

@keyframes pullIn {
    from {
        right: calc(var(--currentViewHeight) * -1);
    }
    to {
        right: 0;
    }
}

@-webkit-keyframes pushOut {
    from {
        visibility: visible;
        right: 0;
    }
    to {
        right: calc(var(--currentViewHeight) * -1);
    }
}

@keyframes pushOut {
    from {
        visibility: visible;
        right: 0;
    }
    to {
        visibility: visible;
        right: calc(var(--currentViewHeight) * -1);
    }
}
