.main-container {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    /* border: 3px solid; */
    padding-top: 2px;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 4px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 8px;
    cursor: pointer;
}

.text-container {
    color: white;
    font-family: var(--sexy);
    font-style: italic;
    text-shadow: 0.4px 0.4px 0.4px hsl(0deg 0% 0% / 48%);

}