.icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon {
    display: block;
}

.icon:active {
    color: white;
}

.flip-icon {
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
}

.icon.shadow {
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
}

.rotate {
    transform: rotate(180deg);
    text-shadow: 0.4px -1px 0.8px hsl(0deg 0% 0% / 0.48);

    /* Legacy vendor prefixes that you probably don't need... */
    -webkit-transform: rotate(180deg); /* Safari */
    -moz-transform: rotate(180deg); /* Firefox */
    -ms-transform: rotate(180deg); /* IE */
    -o-transform: rotate(180deg); /* Opera */
}

.text-icon {
    font-family: var(--sexy);
    font-style: italic;
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
}
