.button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 0;
}

.button-container:active {
    background-color: white;
}

.button-container:disabled {
    background-color: var(--pencil);
    border-color: var(--invisible);
    color: var(--invisible);
    cursor: auto;
}

.inner-container {
    height: 100%;
    width: 100%;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
