
.days-ruler{
    display: grid;
    grid-template-columns: 62px 10px repeat(7, 1fr);
    grid-template-rows: 20px 10px;
}


.day {
    color: var(--pencil);
    font-weight: 300;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    font-size: 16px;
}

.mouseDay {
    /* color: var(--dark); */
    color: var(--aqua);
    font-weight: 700;
    font-size: 20px;
}

.day-content {
    display: block;
    position: relative;
    bottom: -5px;
}

.day-buffer {
    border-left: 2px solid var(--invisible);
    height: 10px;
}
