.main-container {
    display: flex;
    flex-direction: column;
    color: var(--orange);
}

.inner-container {}


.text-container {}
