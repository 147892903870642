.main-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 10;
    margin: 10px ;
}



.text-container {
    z-index: 1;

    color: var(--font-color);
    font-family: var(--sexy);

    -webkit-text-stroke-width: 0.2px;

    font-style: italic;
    font-size: 24px;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);

    cursor: pointer;

    background-color: rgba(0, 0, 0, 0.5);
    padding: 3px 8px 5px 8px;
    border-radius: 5px;
}

.place-subtitle {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 16px;
    margin-top: -1px;

    /* font-family: var(--roboto); */
    font-style: normal;
    color: var(--orange);
}



.money-container {
    display: flex;
    flex-direction: row;    
}

.money-number {
    margin-left: -3px;
    
}

.time-container {
    display: flex;
    flex-direction: row;
}

.time-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.time-number {
    margin-bottom: -4px;
    margin-left: -1px;
}

.time-text {
    font-size: 12px;
    margin-bottom: 2px;
    margin-left: 1px;
}
