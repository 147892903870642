.search-box {
    width: calc(100% - 40px);
    padding-left: 2vh;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.search-icon {
    position: absolute;
    left: 0;
    padding-left: calc(3vh + 5px);
    color: var(--pencil);
    text-shadow: none;
}

.border-box {
    border: 1px solid rgb(1, 1, 1, 0.1);
    border-radius: 50px;
}

.search-input {
    font-family: var(--roboto);

    height: 32px;
    width: 100%;
    border: none;
    border-radius: 50px;
    padding-left: 40px;
}

.cancel-button {
    position: absolute;
    right: 0;
    top: 0;
}
