.drawer-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 8px;
    margin-right: 8px;
    padding: 5px 8px;

    /* color: white; */

    font-family: var(--roboto);
    font-size: 14px;
    font-weight: 450;
    cursor: pointer;

    box-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
    border-radius: 5px;
    height: 30px;

    background-color: black;
    border: 0.5px solid;

}

.drawer-item.large {
    height: 36px;
}

.not-selected {
    background-color: white;
    color: black;
    border-width: 0px;
}


.text-container {
    margin-top: 1px;
}


.shiny {
    font-size: 20px;

    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    
    background-size: 600% 600%;
    animation: gradient 5s ease infinite;
    font-weight: 700;
    
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}