.main-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    /* --row-height: calc((var(--currentViewHeight) - var(--top-bar-height)) / 6); */
    /* --row-height: calc(100% / 6); */
}

.photo-column {
    display: flex;
    flex-direction: column;
}

.photo {
    border: 0.5px solid white;
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
}

.small {
    width: calc(var(--currentViewWidth) / 2);
    height: var(--row-height);
}

.large {
    width: var(--currentViewWidth);
    height: calc(var(--row-height) * 2);
}

.photo-container {
    width: calc(var(--currentViewWidth) / 2);
    height: var(--row-height);
}
