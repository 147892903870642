/* The switch - the box around the slider */

.switch-container {
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: flex-start;
    top:0;
    right: 0;
}

.switch {
    box-sizing: content-box;
    position: relative;
    display: inline-block;
    width: 16px;
    height: 30px;

    border: 3px solid;

    border-bottom-left-radius: 5px;
    border-top-right-radius: 8px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;

    -webkit-transition: 0.4s;
    transition: 0.4s;
}



:not(input:checked) + .slider {
    /* background-color: #ede8d6; */
}

.slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    right: 1px;
    top: 1px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-sizing: border-box;
    /* background-color: var(--light); */
    background-color: white;
    

    /* border-left: 2px solid #dad7c9;
    border-top: 2px solid #dad7c9;
    border-right: 2px solid #a4a4a5;
    border-bottom: 2px solid #a4a4a5; */

    border-left: 2px solid rgba(1, 1, 1, 0.1);
    border-top: 2px solid rgba(1, 1, 1, 0.1);
    border-right: 2px solid rgba(1, 1, 1, 0.3);
    border-bottom: 2px solid rgba(1, 1, 1, 0.3);



    box-shadow: 0px 0.4px 0.4px hsl(0deg 0% 0% / 0.48);
}

input:checked + .slider {
    background-color: rgba(1, 1, 1, 0.1);
}

input:checked + .slider:before {
    -webkit-transform: translateY(14px);
    -ms-transform: translateY(14px);
    transform: translateY(14px);
    
    background-color: var(--invisible);

    /* background-color:rgba(1, 1, 1, 0.4);
    border-left: 2px solid rgba(255, 255, 255, 0.9);
    border-top: 2px solid rgba(255, 255, 255, 0.9);
    border-right: 2px solid rgba(255, 255, 255, 0.7);
    border-bottom: 2px solid rgba(255, 255, 255, 0.7); */
}

/* Rounded sliders */
.slider.round {
    /* border-radius: 3px; */
}

.slider.round:before {
    border-radius: 5px;
}
