.curtain-hider {
    position: absolute;
    top: 0;
    z-index: 30;

    height: var(--stage-size);
    width: 100%;

    overflow: hidden;
}

.stage-curtain {
    opacity: 100%;
    z-index: 30;
    visibility: hidden;
    position: absolute;

    height: var(--stage-size);
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: var(--dark);

    color: white;
    font-family: var(--sexy);
}

.stage-shadow {
    position: relative;
    top: 0;
    width: 100%;
    /* height: 1px; */
    z-index: 100;
    box-shadow: 0px 1px 3.2px 2px hsl(0deg 0% 0% / 0.45);
}

.curtain-image {
    position: absolute;
    top: 0;
    display: block;
    height: var(--stage-size);
    width: 100%;
    object-fit: cover;
}

.text-container {
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 30%;
}

.super-title {
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
    font-size: 22px;
    /* margin-bottom: -12px; */
    font-style: italic;
}

.main-title {
    text-align: center;
    line-height: 90%;
    padding: 0 24px;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
    font-size: 54px;
    font-style: italic;
    cursor: pointer;
    font-weight: 700;
}

.bottom-stage-shadow {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* height: 1px; */
    z-index: 100;
    box-shadow: 0px -1px 3.2px 3px hsl(0deg 0% 0% / 0.45);
}

.stage-curtain.opened {
    /* Invisible by default */
}

.stage-curtain.closed {
    visibility: visible;
    pointer-events: all;
}

.stage-curtain.close {
    visibility: visible;
    animation: closeCurtain var(--close-speed, 800);
}

.stage-curtain.open {
    animation: openCurtain var(--open-speed, 800);
}

@keyframes closeCurtain {
    0% {
        visibility: visible;
        top: calc(-1 * var(--stage-size));
    }

    100% {
        top: 0;
    }
}

@keyframes openCurtain {
    0% {
        visibility: visible;
        top: 0;
    }

    100% {
        visibility: visible;
        top: calc(-1 * var(--stage-size));
    }
}
