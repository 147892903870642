.main-container {
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
    
    border-bottom: 20px;
    /* overflow: hidden; */
    position: relative;

    height: 42px;
    width: 40px;
    cursor: pointer;
    
    
}

.image-container {
    position: absolute;
    bottom: 0;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    overflow: hidden;
}

.user-image{    
    height: 64px;
    width: 36px;
    object-fit: cover;
    position: absolute;
    top: -2px;
}