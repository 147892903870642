.main-container {
    display: flex;
    flex-direction: column;

    height: var(--stage-size);
    width: 100%;

    pointer-events: none;
    position: relative;
}

