.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:var(--stage-size);
    height:var(--stage-size);
    
    position: relative;
}


.image {
    width: 100%;
    padding: 0;
    display: block;
    position: absolute;
}