.input-container {
    margin-top: 1rem;
    border: 3px solid var(--dark);
    height: 60px;
    border-radius: 5px;
    display: flex;
}


.input-container:hover{
    box-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.45);
}

.input-icon-container {
    width: 55px;
    background-color: var(--dark);
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.input-icon {
    color: white;
    font-size: 24px;
}


.input-text{
    
    cursor: pointer;
    width: 100%;
    border: none;
    font-family: inherit;
    font-size: 16px;
    
    padding-left: 20px;
    font-family: var(--roboto);
    
}

.input-text:focus {
    outline: none;
}