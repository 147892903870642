.invitations-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: calc(var(--currentViewHeight) - var(--stage-size) - var(--top-bar-height) - var(--top-bar-height)); */
    
    /* margin-top: -5%; */
}

.navigation-buttons {
    width: 100%;
    margin-top: 7%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}