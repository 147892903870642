.choice {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    width: 120px;
    padding-bottom: 5px;

    cursor: pointer;

    border-width: 3px;
    border-radius: 8px;

    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
    background-color: var(--red);

    pointer-events: all;

    color: var(--light);
}

.button-container {
    border: 1px solid var(--light);
    border-radius: 8px;
    height: 52px;
}





.choice:active{
    background-color: white;
    color: var(--red);
    text-shadow: none;
}


.choice-icon {
    text-align: center;
}

.choice-text {
    font-size: 20px;
    font-family: var(--sexy);
    text-align: center;
    font-style: italic;
}
