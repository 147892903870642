.outer-container {
    position: relative;
    width: var(--thumbnail-size);
    height: var(--thumbnail-size);
    margin-bottom: var(--thumbnail-gap);
}

.main-container {
    position: relative;

    height: 100%;
    width: 100%;

    border: 3px solid;
    border-radius: 8px;
    /* box-shadow: .8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48); */
    box-shadow: 0.8px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
}

.thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    padding: 0;
    display: block;
}

.item-off {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}
