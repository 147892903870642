.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;    
    font-size: 18px;
    height: var(--currentViewHeight);
    position: relative;
}

.sign-in-title {
    margin-top: 3rem;
    font-size: 50px;
    font-style: italic;
    font-weight: 700;
    font-family: var(--sexy);

    color: white;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
}

.reset-password-title {
    margin-top: 3rem;
    font-size: 40px;
    font-style: italic;
    font-weight: 700;
    font-family: var(--sexy);


    color: white;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);

    text-align: center;
    line-height: 90%;
}

.form-container {
    margin-top: 2rem;
    /* min-width: 260px; */
    width: 80%;
}

.or {
    margin-top: 1rem;
    text-align: center;
    font-weight: 700;
}

.password-forgotten {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin-top: 0.8rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
}

.password-forgotten:hover {
    font-weight: 700;
    cursor: pointer;
}

.error-message {
    color: var(--red);
    text-align: center;
    margin-top: 1rem;
}
