.main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    padding: 0 16px;
    margin-top: 16px;
    margin-bottom: 16px;
}
