.main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 30%;

    font-size: 20px;
    font-weight: bold;
    /* color: var(--light); */
    color: var(--font-color);
    font-family: var(--sexy);
    font-style: italic;

    border-radius: 5px;

    box-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);

    padding-bottom: 2px;
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
    border: 1px solid rgb(255, 255, 255, 0.5);
    cursor: pointer;
}

.main-container:active {
    background-color: var(--dark);
}

.neutral-topics {
    background-color: rgb(255, 255, 255, 0.2);
    border: 1px solid rgb(255, 255, 255, 0.5);
}

.neutral-topics.is-active {
    background-color: transparent;
    border: none;
    width: 26%;
    font-size: 24px;

    box-shadow: none;
}

.is-active {
    background-color: transparent;
    border: none;
    width: 30%;
    font-size: 28px;
    box-shadow: none;
}


.light-topics {
}

.light-topics.is-active {
    background-color: rgb(255, 255, 255, 0.15);
}

.main-container.is-active.left-section {
    border-left: none;
}

.main-container.is-active.right-section {
    border-right: none;
}

.text-container {
    margin-left: 3px;
}
