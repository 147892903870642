.main-container {
    display: flex;
    flex-direction: column;

    height: var(--event-thumbnail-size);
    width: var(--event-thumbnail-size);

    align-items: center;
    justify-content: center;
    font-family: var(--casual);

    background-color: black;
    color: var(--aqua);

    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 48%);

    border: 3px solid var(--aqua);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    

    position: relative;
}

.main-container.past-date{
    background-color: grey;
    color: var(--invisible);
}

.weekday-container {
    font-size: 20px;
    font-weight: bold;
    position: relative;
    height: 38px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    margin-top: -5px;
}

.time-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    margin-top: 3px;
}


.date-box {
    margin-top: -15px;

    display: flex;
    flex-direction: column;
    /* border: 3px solid black; */
    border: 3px solid var(--aqua);
    border-radius: 8px;
    align-items: center;
    width: 35px;
    height: 35px;
    box-sizing: content-box;
}

.date-box.past-date {
    border-color: var(--grey);
}

.date-month {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    height: 14px;
    /* background-color: black; */
    background-color: var(--aqua);
    width: 100%;
    color: white;
    text-align: center;

    justify-content: center;
    align-items: center;
}

.date-month.past-date {
    color: var(--invisible);
    background-color: var(--grey);
}


.date-day.past-date{
    background-color: grey;
    color: var(--invisible);
    
}



.date-day {
    background-color: white;
    color: var(--aqua);

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 100%;
    height: 100%;

    padding: 8px;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /* text-shadow: none; */
}



.date-day-text {
    height: 14px;
    font-size: 18px;
    margin-top: -10px;
}

.shiny {
    text-shadow: none;
    position: absolute;
    top: 0;

    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);

    background-size: 600% 600%;
    animation: gradient 5s ease infinite;
    font-weight: 700;

    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.weekday-white {
    position: absolute;
    top: 0;
}
