.main-container {
    display: flex;
    flex-direction: column;

    height: 100%;

    color: white;

    font-family: var(--roboto);
}

.inner-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}

.text-container {
    text-align: center;
    font-size: 20px;
    color: var(--red);
}

.image-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.image {
    border: 0.5px solid white;
    border-radius: 8px;
}

.shame-container {
    margin-top: 40px;
    text-align: center;
    font-size: 48px;
    font-weight: 800;
}
