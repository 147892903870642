.buttons-bar {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    height: var(--browser-buttons-height);
    width: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
    pointer-events: none;
}
