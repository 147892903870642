.main-container {
    background-color: var(--light);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    margin-bottom: 16px;
    padding: 16px;
    padding-top: 0;
    
    
}

.border-container{
    border: 2px solid var(--orange);
    border-radius: 8px;
    background-color: var(--dark);
    color: white;
}


.map-container {
    position: relative;
    /* height: var(--stage-size); */
    height: 300px;
    width: 100%;    
    
    overflow: hidden;
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: var(--dark);
    width: 100%;
    height: 100%;
    border-radius: 6px;

    color: var(--orange);
}

.place-notes {
    width: 100%;
    font-size: 16px;
    font-family: var(--roboto);
    resize: none;
    background-color: transparent;
    border: none;
    padding: 8px;
    padding-left: 16px;
    color: white;
}

.place-notes:focus{outline: none;}