.main-container {
    display: flex;
    flex-direction: column;
    color: white;
    opacity: 0.4;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    
    position: absolute;
    z-index: 1;

    right: 0;
    bottom: calc(var(--browser-space-left) / 3);
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
    pointer-events: all;
}

.inner-container {
}

.text-container {
}
