.black-background {
    background-color: black;
    width: 100%;
}

.main-container {
    position: relative;
    width: 100%;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    height: var(--top-bar-height);

    color: var(--font-color);
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);

    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    /* border-bottom: 3px solid rgb(1, 1, 1, 0.2);

    border-right: 3px solid rgba(255, 255, 255, 0.2);
    border-left: 3px solid rgba(255, 255, 255, 0.2); */
}
