.outer-container {
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 10;

    width: 80px;
    height: 80px;

    cursor: pointer;
    border-top: 1px solid white;
    border-left: 1px solid white;

    border-top-left-radius: 9px;

    box-shadow: -2px -1.5px 3.2px hsl(0deg 0% 0% / 0.45);
}

.main-container {
    position: relative;

    border: 5px solid;
    border-top-left-radius: 8px;

    width: 100%;
    height: 100%;
}

.icon-container {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;

    color: var(--dark);
    background-color: white;
}

.icon-container:not(:active) {
    /* color: var(--light); */
    color: white;
    background-color: var(--dark);
}

.switch-container {
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: flex-start;
    top: 0;
    right: 0;
}

.switch {
    box-sizing: content-box;
    position: relative;
    display: inline-block;
    width: 8px;
    height: 15px;

    border: 3px solid;

    border-bottom-left-radius: 5px;
    border-top-right-radius: 8px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
}

.name-container {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    /* border: 3px solid; */
    padding-top: 2px;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 4px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 8px;
}

.text-container {
    color: white;
    font-family: var(--sexy);
    font-style: italic;
    text-shadow: 0.4px 0.4px 0.4px hsl(0deg 0% 0% / 48%);
}
