.outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    height: 100%;
    width: 100%;

    background-color: var(--dark);
}

.main-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 50px 24px 24px 24px;
    max-width: 360px;
}
