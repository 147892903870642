.main-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 100%;    
    background-color: var(--light);
    margin-bottom: 16px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: hidden;
}

.time-container {
    max-width: 400px;
    width: 100%;
    
    padding: 0px 16px 16px 0px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    
}

.section-title-container {
    padding-left: 16px;




    background-color: var(--aqua);

}

.time-select {
    grid-area: time-select;
    display: grid;
    grid-template-areas:
        'days-ruler'
        'visible-times';
    width: 100%;

    margin-left: -3px;
    overflow-x: auto;
    
}

.visible-hours {
    height: 290px;
    overflow-y: auto;
}

.hidden {
    visibility: hidden;
}

/* Forces scroll bar to always be visible on Chrome, but doesn't work on Firefox */
/* .visible-hours::-webkit-scrollbar {
    -webkit-appearance: none;
}

.visible-hours::-webkit-scrollbar:vertical {
    width: 11px;
}

.visible-hours::-webkit-scrollbar:horizontal {
    height: 11px;
}

.visible-hours::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid var(--light); 
    background-color: rgba(0, 0, 0, .5);
} */
