.main-container {
    display: flex;
    flex-direction: column;
    color: white
}

.inner-container {}


.text-container {}
