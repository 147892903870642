.main-container {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: row;
    color: white;
    margin-bottom: 16px;
    justify-content: space-between;
    /* font-family: var(--casual); */
}

.menu-photo {
    width: 100%;
    border-top: 0.5px solid white;
    border-bottom: 0.5px solid white;
    display: block;
}
