.time-block {
    border-left: 2px solid var(--invisible);
    border-top: 2px solid var(--invisible);
    cursor: pointer;
}

.time-block.half-hour {
    border-top: none;
}

.time-block:hover {
    background-color: var(--invisible);
}

.selected {
    /* background-color: var(--dark); */
    background-color: var(--aqua);
    opacity: 0.7;
}


.time-block.selected:hover{
    /* background-color: var(--dark); */
    background-color: var(--aqua);
    opacity: 0.7;
}


.selected.closed {
    background-color: grey;
}

.time-block.selected.closed:hover{
    background-color: grey;
}