.main-container {
    visibility: hidden;
    width: 100%;
    /* height: calc(var(--currentViewHeight) - 5vh); */
    height: var(--currentViewHeight);
    z-index: 20;
    position: absolute;
    /* bottom: 5vh; */
    bottom: 0vh;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    box-shadow: 0px 2px 2px hsl(0deg 0% 0% / 0.45);

    overflow: hidden;
    background-color: var(--dark);
}

.children-container-outer {
    width: 100%;
    height: calc(var(--currentViewHeight) - var(--top-bar-height));
    bottom: 0;
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.children-container {
    max-width: 650px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.main-container.show {
    visibility: visible;
    -webkit-animation: pullUp 0.3s;
    animation: pullUp 0.3s;
}

.main-container.hide {
    -webkit-animation: pullDown 0.3s;
    animation: pullDown 0.3s;
}

@-webkit-keyframes pullUp {
    from {
        bottom: calc(var(--currentViewHeight) * -1);
    }
    to {
        /* bottom: 5vh; */
        bottom: 0vh;
    }
}

@keyframes pullUp {
    from {
        bottom: calc(var(--currentViewHeight) * -1);
    }
    to {
        /* bottom: 5vh; */
        bottom: 0vh;
    }
}

@-webkit-keyframes pullDown {
    from {
        visibility: visible;
        /* bottom: 5vh; */
        bottom: 0vh;
    }
    to {
        bottom: calc(var(--currentViewHeight) * -1);
    }
}

@keyframes pullDown {
    from {
        visibility: visible;
        /* bottom: 5vh; */
        bottom: 0vh;
    }
    to {
        visibility: visible;
        bottom: calc(var(--currentViewHeight) * -1);
    }
}
