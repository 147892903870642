.main-container {
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
    width: 100%;
    color: white;
}


.postman-image{
    width: var(--currentViewWidth);
    /* height: calc(var(--currentViewHeight) - var(--top-bar-height)); */
    height: 100%;
    object-fit: cover;
}

.text-container {
    text-align: center;
    font-size: 20px;
    position: absolute;
    z-index: 1;
    padding: 16px;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
    
}
