.button-container {
    height: 75px;
    width: 75px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    cursor: pointer;

    border-radius: 50%;
    border: 4px solid;
    box-shadow: 0px 3.2px 3.2px hsl(0deg 0% 0% / 0.45);
    pointer-events: all;
}

.button-container:active {
    background-color: white;
}

.button-container:disabled {
    background-color: var(--pencil);
    border-color: var(--invisible);
    color: var(--invisible);
    cursor: auto;
}

.button-container:disabled:not(:active){
    color: var(--invisible);
}


.button-container:not(:active) {
    color: var(--font-color);
    border-color: rgb(255, 255, 255, 0.2);
}

.timer-container {
    position: absolute;
    z-index: 10;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: rgb(1, 1, 1, 0.7);

    font-weight: 700;

    font-size: 30px;
}

.icon {
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
}


.text-icon {
    font-family: var(--sexy);
    font-size: 32px;
    font-style: italic;
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
}


.flip-icon {
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
}

.rotate {
    transform: rotate(180deg);
    text-shadow: 0.4px -1px 0.8px hsl(0deg 0% 0% / 0.48);

    /* Legacy vendor prefixes that you probably don't need... */
    -webkit-transform: rotate(180deg); /* Safari */
    -moz-transform: rotate(180deg); /* Firefox */
    -ms-transform: rotate(180deg); /* IE */
    -o-transform: rotate(180deg); /* Opera */
}
