.outer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    padding: 24px;

}


.main-container {
    width: 100%;
}

.bill-section {
    color: var(--light);
    font-size: 24px;
    font-family: var(--sexy);
    font-style: italic;
    margin-top: 16px;
    text-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.48);
    margin-left: -3px;
}

.notes {
    color: var(--orange);
}


.inner-container {}


.text-container {}
