.main-container {    
    width: 100%;
    height: 100%;
}

.inputs-container {
    background-color: var(--light);
    padding: 16px;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 16px;
}