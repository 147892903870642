.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 44px;
    width: 124px;
    

    cursor: pointer;
    border-top: solid 1px white;

    pointer-events: all;
}

.main-container.left {
    border-top-right-radius: 12px;
    border-right: solid 1px white;
    box-shadow: 2px -1.5px 3.2px hsl(0deg 0% 0% / 0.45);
}

.main-container.right {
    border-top-left-radius: 12px;
    border-left: solid 1px white;
    box-shadow: -2px -1.5px 3.2px hsl(0deg 0% 0% / 0.45);
}



.inner-container {
    
    color: var(--light);
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
    
    display: flex;
    flex-direction: row;

    margin-top: -2px;
}


.text-container {
    font-family: var(--sexy);
    font-size: 20px;
    margin-left: 5px;
}
