.main-container {
    display: flex;
    flex-direction: row;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 48%);
    /* font-family: var(--roboto); */
}

.notice-text,
.label-text{
    color: white;
    font-family: var(--sexy);
    font-style: italic;
}

.notice-text,
.minimum-sign {
    font-size: 14px;
}

.notice-sign {
    font-size: 24px;
}


.notice-container {
    
    color: white;    
    margin-left: 8px;
}

.notice-text{
text-align: center;
}


.numbers-container {
    display: flex;
    flex-direction: row;
}


.notice-number {
    display: block;
    /* border: 1px solid var(--aqua); */
    width: 36px;
    height: 30px;
    padding: 0;
    border: 0;
    font-size: 24px;
    text-align: center;
    /* background-color: var(--aqua); */
    background-color: rgba(255, 255, 255, 0.2);
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 48%);
    border-radius: 5px;
    color: white;
}