.image-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
}

.background-image {
    width: 100%;
    height: var(--currentViewHeight);
    height: var(--currentViewHeight);
    
    object-fit: cover;
    position: relative;
    vertical-align: top;
}
