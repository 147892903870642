.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    color: white;

    z-index: 1;
    width: 40px;
    height: 40px;

    position: absolute;
    bottom: 5px;
    right: 2px;
    z-index: 10;
}
