.main-container {
    height: 100%;
    width: 100%;
    /* background-color: blue; */
    position: relative;
}

.schedule-form {
    width: 100%;
    height: calc(100% - 30px);
    position: absolute;
    bottom: 0;
}

.back-bar {
    width: 100%;
    height: 36px;
    position: absolute;
    top: 0;
    background-color: white;
    z-index: 11;
    display: flex;
    justify-content: center;
}

.back-super-button {
    width: 100%;
    height: 100%;
    color: white;
    background-color: black;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom: solid 1px white;
    border-right: solid 1px white;
    border-left: solid 1px white;
    box-shadow: 0px 1.5px 6px hsl(0deg 0% 0% / 0.6);

    display: flex;
    /* flex-direction: column; */
    align-items: center;
}

.back-super-button:active {
    background-color: white;
    color: black;
}

.bar-logo-outer{
    width: 100%;
    display: flex;
    justify-content: center;
}

.bar-logo-container {
    position: relative;
    /* width: 42px; */
    width: 50px;  
    height: 28px;
}

.bar-back-to-text {
    position: absolute;
    font-family: var(--sexy);
    font-style: italic;

    font-size: 16px;
    font-weight: 700;
    margin-left: 16px;

}

.back-button {
    background-color: black;
    position: absolute;
    z-index: 11;

    height: 42px;
    width: 56px;
    color: white;
    /* box-shadow: 2px -1.5px 3.2px hsl(0deg 0% 0% / 0.45); */
}

.back-button:active {
    background-color: white;
    color: black;
    font-weight: bold;
}

.bottom-left {
    bottom: 0;
    left: 0;
    border-top-right-radius: 12px;
    border-top: solid 1px white;
    border-right: solid 1px white;
    box-shadow: 2px -1.5px 6px hsl(0deg 0% 0% / 0.6);
}

.top-left {
    top: 0;
    left: 0;
    border-bottom-right-radius: 12px;
    border-bottom: solid 1px white;
    border-right: solid 1px white;
    box-shadow: 2px 1.5px 6px hsl(0deg 0% 0% / 0.6);
}

.logo-container {
    position: relative;

    margin-left: 6px;
    margin-top: 3px;
}

.back-to-text {
    font-family: var(--sexy);
    font-style: italic;

    font-size: 12px;
    margin-left: 3px;
    margin-bottom: -6px;
}
