.main-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    position: absolute;
    
    bottom: 0px;
    pointer-events: none;
}
