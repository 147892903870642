.media-box {
    position: absolute;

    width: 100%;
    max-width: 600px;    
}

.tutorial-mode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}



.play-pause-button {
    pointer-events: all;
    /* background-color: red; */
    position: absolute;
    width: 100%;
    height: 85%;
    top: 0%;
    z-index: 1;
}
