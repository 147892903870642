.month-select {
    display: flex;
    margin-top: 5vw;
    color: white;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 48%);
}

.month-title {
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    width: 220px;
}

.month-selector {
    display: flex;
    font-size: 24px;
    font-weight: 700;
    width: 20px;
}

.righ-month-selector {
    display: flex;
    font-size: 24px;
    font-weight: 700;
    width: 20px;

    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.month-selector:hover,
.righ-month-selector:hover{
    cursor: pointer;
    font-size: 32px;
    margin-top: -5px;
}