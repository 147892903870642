.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: all;

    color: white;
    position: absolute;
    z-index: 10;
    cursor: pointer;
}

.help-button {
    opacity: 0.4;
    bottom: 6px;
    left: 2px;
    width: 40px;
    height: 40px;
}

.got-it-button {
    bottom: 0;
    left: 0;
    height: 40px;
    width: 80px;

    border-top-right-radius: 12px;
    border-top: solid 2px white;
    border-right: solid 2px white;
    /* box-shadow: 2px -1.5px 3.2px hsl(0deg 0% 0% / 0.45); */
    box-shadow: 2px -1.5px 6px hsl(0deg 0% 0% / 0.6);
}

.got-it-text {
    font-family: var(--sexy);
    font-size: 20px;
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
    font-style: italic;
    /* font-weight: 700; */
}
