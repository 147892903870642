.main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    pointer-events: all;
}

.text-button {
    font-family: var(--sexy);
    font-style: italic;
    text-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.48);
    font-size: 40px;
    width: 140px;
    -webkit-text-stroke-width: 0px;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
    color: var(--aqua);
}

.choose-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 140px;
    height: 50px;
}

.shiny {
    position: absolute;
    top: 0;
    z-index: 1;
}

.white-shadow {
    color: white;
    text-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.48);
    position: absolute;
    top: 0;
}

.text-button:active {
    color: white;
}

.text-button.shiny {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab, white);
    background-size: 600% 600%;
    animation: gradient 5s ease infinite;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
