.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: white;

    height: 100%;
    width: 100%;
}

.thumbnail-container {
    background-color: var(--dark);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    --map-thumbnail-size: calc(min(70vw, 260px));
    width: var(--map-thumbnail-size);
    height: calc(var(--map-thumbnail-size) * 0.56);

    border-bottom-right-radius: 13px;
    overflow: hidden;
    border-right: 2px solid var(--orange);
    border-bottom: 2px solid var(--orange);
    box-shadow: 2px 1.5px 3.2px hsl(0deg 0% 0% / 0.45);
}

.thumbnail {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.map-container {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.loading-container {
    position: absolute;
    z-index: -1;
}
