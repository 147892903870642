.stage-lights {
    position: absolute;
    top: 0;
    /* height: var(--stage-size); */
    height: 100%;
    width: 100%;
    background-color: black;
    visibility: hidden;
    z-index: 25;
}

.stage-lights.on {
    /* Invisible by default */
}

.stage-lights.off {
    visibility: visible;
}

.stage-lights.turn-off {
    visibility: visible;
    animation: turnLightsOff var(--close-speed, 0.8s);
}

.stage-lights.turn-on {
    animation: turnLightsOn var(--open-speed, 0.8s);
}

@keyframes turnLightsOff {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}

@keyframes turnLightsOn {
    0% {
        visibility: visible;
        opacity: 100%;
    }

    100% {
        visibility: visible;
        opacity: 0%;
    }
}
