.main-container {
    visibility: hidden;
    max-width: 50px;
    height: 50px;
    /*margin-left: -125px;*/
    margin: auto;
    background-color: rgb(1, 1, 1, 0.5);
    color: var(--light);
    text-align: center;
    border-radius: 8px;

    position: absolute;
    z-index: 26;
    left: 0;
    right: 0;
    bottom: 10%;
    font-size: 20px;
    white-space: nowrap;
}

.main-container .icon-container {
    width: 50px;
    height: 50px;

    float: left;

    box-sizing: border-box;

    /* background-color: rgb(1, 1, 1, 0.5); */
    color: var(--light);
}

.text-container,
.icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
}

.icon {
    display: block;
}

.main-container .text-container {
    color: var(--light);

    padding-right: 35px;

    overflow: hidden;
    white-space: nowrap;
    height: 100%;
}

.text-content {
    display: block;
    opacity: 0;
}

.main-container.show {
    -webkit-animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s, shrink 0.5s 2s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s, shrink 0.5s 4s, fadeout 0.5s 4.5s;
}

.show .text-content {
    -webkit-animation: fadein 0.5s 0.5s, stay 3.2s 1s;
    animation: fadein 0.5s 0.5s, stay 3.2s 1s;
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
        visibility: visible;
    }
    to {
        bottom: 10%;
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
        visibility: visible;
    }
    to {
        bottom: 10%;
        opacity: 1;
        visibility: visible;
    }
}

@-webkit-keyframes expand {
    from {
        min-width: 50px;
        visibility: visible;
    }
    to {
        min-width: 350px;
        visibility: visible;
    }
}

@keyframes expand {
    from {
        min-width: 50px;
        visibility: visible;
    }
    to {
        min-width: 350px;
        visibility: visible;
    }
}
@-webkit-keyframes stay {
    from {
        min-width: 350px;
        opacity: 1;
        visibility: visible;
    }
    to {
        min-width: 350px;
        opacity: 1;
        visibility: visible;
    }
}

@keyframes stay {
    from {
        min-width: 350px;
        opacity: 1;
        visibility: visible;
    }
    to {
        min-width: 350px;
        opacity: 1;
        visibility: visible;
    }
}
@-webkit-keyframes shrink {
    from {
        min-width: 350px;
        visibility: visible;
    }
    to {
        min-width: 50px;
        visibility: visible;
    }
}

@keyframes shrink {
    from {
        min-width: 350px;
        visibility: visible;
    }
    to {
        min-width: 50px;
        visibility: visible;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 10%;
        opacity: 1;
        visibility: visible;
    }
    to {
        bottom: 15%;
        opacity: 0;
        visibility: visible;
    }
}

@keyframes fadeout {
    from {
        bottom: 10%;
        opacity: 1;
        visibility: visible;
    }
    to {
        bottom: 15%;
        opacity: 0;
        visibility: visible;
    }
}
