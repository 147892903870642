.main-container {
    --tiny-size: 48px;
    box-shadow: 0.8px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
    border-radius: 3px;

    height: var(--tiny-size);
    width: var(--tiny-size);
    margin-left: 8px;
}

.thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
    padding: 0;
    display: block;
}
