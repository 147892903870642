.main-container {
    margin-top: 5vw;
    width: 100%;
    padding: 0 5vw 0 5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.inner-container {}


.text-container {}
