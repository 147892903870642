.choice-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.choice {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    width: 100px;
    padding-bottom: 5px;

    cursor: pointer;

    border-width: 3px;
    border-radius: 8px;

    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
}

.button-container {
    border: 1px solid var(--light);
    border-radius: 8px;
    height: 52px;
}

.choice:not(:hover) {
    /* color: white; */
    color: var(--light);
}

.choice:hover {
    background-color: white;
    text-shadow: none;
}

.choice-icon {
    text-align: center;
}

.choice-text {
    font-size: 20px;
    font-family: var(--sexy);
    text-align: center;
}
