.main-container {
    width: 100%;
    height: calc(var(--currentViewHeight) - var(--top-bar-height));
    bottom: 0;

    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;

    /* font-family: var(--roboto); */
}

.children-container {
    width: 100%;
    max-width: 750px;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    overflow-y: auto;
}