.main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-family: var(--sexy);
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    font-size: 16px;
    pointer-events: none;
    margin-bottom: 8px;
    justify-content: space-evenly;
    
}

.inner-container {
    box-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.45);
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
    background-color: var(--orange);
    pointer-events: all;
    padding: 5px;
    border: 1px solid white;
    /* width: 120px; */
    border-radius: 8px;
    margin-top: 24px;
}


.text-container {}
