
.actions {
    grid-area: actions;
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-items: center;
    align-items: center;
    margin-top: 2rem;
}


.confirm {
    padding: 0.7rem 1.5rem 0.5rem 1.5rem;
    font-size: 20px;
    border-width: 3px;
    border-radius: 8px;
    font-family: inherit;
    cursor: pointer;
    display: block;
    min-height: 58px;
    background-color: var(--aqua);
    border-color: var(--aqua);
    max-width: 220px;
    line-height: 120%;
}


.confirm:not(:hover){
    color: white;
}

.confirm:hover {
    background-color: white;    
    color: var(--aqua);
    
}


.confirm:disabled {
    background-color: var(--pencil);
    border-color: var(--invisible);
    color: var(--invisible);
    cursor:auto;
}



.cancel {
    padding: 0.7rem 1.5rem 0.5rem 1.5rem;
    font-size: 20px;
    border-width: 3px;
    border-radius: 8px;
    font-family: inherit;
    cursor: pointer;
    display: block;
    height: 58px;

    color: var(--brown);
    border-color: var(--brown);
    background-color: var(--light);
    font-weight: 300;
    border: none;
    margin-top: 4rem;
}

.cancel:hover {
    font-weight: 700;
}

.cancel:focus {
    outline: none;
}
