.outer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}


.main-container {
    width: 100%;
    height: calc(var(--currentViewHeight) - var(--top-nav-bar));
    max-width: 650px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;

    /* padding: min(4vw, 16px); */
    padding: 4%;
    /* background-color: var(--dark); */
    overflow-y: auto;
}
