.main-container {
    height: 100%;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 24px;
    background-color: var(--dark);
}

.text-container {
    color: white;
    font-family: var(--casual);
    font-size: 30px;
    line-height: 120%;
    text-align: center;
}