.main-container {
    width: var(--thumbnail-size);
    height: calc(var(--thumbnail-size) * 1.3);

    display: flex;
    justify-content: center;

    border-radius: 8px;

    margin-bottom: var(--thumbnail-gap);
    box-shadow: 1.6px 2.4px 3.2px hsl(0deg 0% 0% / 0.45);

    /* box-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48); */
    /* border-top: 2px solid rgba(255, 255, 255, 0.1);
    border-bottom: 2px solid rgba(1, 1, 1, 0.2);
    
    border-right: 2px solid rgba(1, 1, 1, 0.2);
    border-left: 2px solid rgba(255, 255, 255, 0.1); */

    cursor: pointer;
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
}

.main-container:not(:active) {
    color: var(--font-color);
}

.main-container:active {
    border: 5px solid;
}

.inner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 5px;

    overflow: hidden;
}

.product-image{
    padding: 20px;
    height: 100%;
}


.inner-box:active {
    background-color: white;
}

.title-container {
    width: 80%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.top-title,
.title-text {
    font-family: var(--sexy);
    font-style: italic;
    font-weight: 700;
}

.top-title {
    font-size: 20px;
    margin-bottom: -13px;
}

.title-text {
    font-size: 40px;
}
