.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    /* height: calc(min(100vw, 100%)); */
    height: var(--stage-size);

    /* background-color: var(--dark); */
    position: absolute;
    top: 0;
    z-index: 20;
    pointer-events: all;
}

.name-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}
