.main-container {
    visibility: hidden;
    position: absolute;
    top: 0;
    /* right: 0; */
    z-index: 100;
    height: 100%;
    width: 100%;
    background-color: black;

}

.curtain {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}


.curtain-image {
    height: 100%;
    /* Comment out the width if it's looking strange. */
    /* width: 100%;  */
    object-fit: cover ;
}

.main-container.show {
    animation: rightToLeft 0.8s;
}

.main-container.hide {
    animation: leftToRight 0.8s;
}


@keyframes topToBottom {
    0% {
        visibility: visible;
        top: calc(var(--currentViewHeight) * -1);
    }
    
    25% {
        
        top: 0vh;
    }
    
    75% {
        
        top: 0vh;
    }

    100% {
        visibility: hidden;
        top: calc(var(--currentViewHeight) * -1);
    }
}



@keyframes bottomToTop {
    0% {
        visibility: visible;
        bottom: calc(var(--currentViewHeight) * -1);
    }
    
    25% {
        
        bottom: 0vh;
    }
    
    75% {
        
        bottom: 0vh;
    }

    100% {
        visibility: hidden;
        bottom: calc(var(--currentViewHeight) * -1);
    }
}






@keyframes rightToLeft {
    0% {
        visibility: visible;
        right: calc(var(--currentViewWidth) * -1);
    }
    
    25% {
        
        right: 0;
    }
    

    75% {
        
        left: 0
    }

    100% {
        visibility: hidden;
        left: calc(var(--currentViewWidth) * -1);
    }
}


@keyframes leftToRight {
    0% {
        visibility: visible;
        left: calc(var(--currentViewWidth) * -1);
    }
    
    25% {
        
        left: 0;
    }
    

    75% {
        
        right: 0
    }

    100% {
        visibility: hidden;
        right: calc(var(--currentViewWidth) * -1);
    }
}





