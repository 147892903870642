.main-container {
    
    position: absolute;
    top: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    height: var(--currentViewHeight);
    width: var(--currentViewWidth);

    background-color:  rgba(0, 0, 0, 0.8);
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);

    z-index: 100;
    padding-bottom: 30%;

    font-weight: bold;
    color: white;
}


.clear-x {
    position: absolute;
    padding: 16px;
    top: 0;
    right: 0;
    color: white;
    cursor: pointer;
}


.inner-container {}


.text-container {}
