.main-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;
    width: 100%;
}

.title-container {
    color: white;
    font-size: 32px;
    line-height: 100%;
    margin-bottom: 1vh;
    font-weight: 700;

    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
}

.categories-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    align-content: flex-start;
}
