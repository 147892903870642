.main-container{}

.text-container {
    color: white;

    font-family: var(--sexy);
    font-size: 32px;
    font-style: italic;
    cursor: pointer;
    /* margin-top: -3px; */
    font-weight: 700;

    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 48%);
}
