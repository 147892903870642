.main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;


    background-color: var(--light);
    padding: 16px 20px;
}

.title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 120px;

}

.thumbnail-container {
    
    
}