.main-container {
    position: absolute;
    z-index: 10;
    padding: 10px 20px;
}

.name-container {
    display: flex;
}


.element-name {
    color: var(--font-color);
    font-family: var(--sexy);

    -webkit-text-stroke-width: 0.2px;

    font-style: italic;
    font-size: 24px;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);

    cursor: pointer;
}


.prediction-icon-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.prediction-text-container{
    margin-top: -3px;
}





.prediction {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 600% 600%;
    animation: gradient 5s ease infinite;

    font-size: 14px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: var(--sexy);
    font-weight: 700;
    font-style: italic;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
