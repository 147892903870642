.main-container {
    margin-top: 40px;

    display: flex;
    flex-direction: column;

    font-family: var(--roboto);

    width: calc(var(--event-thumbnail-size) * 3);
}




.event-element {
 color: var(--aqua);
}


.message-line {
    
    /* margin-bottom: 16px; */
    
    
}