.input-container {
    border-radius: 5px;
    display: flex;
    background-color: transparent;
    padding-right: 16px;
    padding: 8px;
    margin-bottom: 16px;
}

.input-icon-container {
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.input-icon {
    font-size: 24px;
    background-color: transparent;
}

.input-text {
    font-family: var(--roboto);

    cursor: pointer;
    width: calc(100% - 45px);
    border: none;
    /* font-family: inherit; */
    font-size: 20px;

    line-height: 140%;

    /* height: 36px; */

    background-color: transparent;
    border-bottom: 2px solid var(--purple);

    resize: none;

    margin-left: 8px;
}

.input-text:focus {
    outline: none;
}
