.hours-row {
    display: grid;
    grid-template-columns: 62px 10px repeat(7, 1fr);
    grid-template-rows: 20px 20px;
}



.hour {
    color: var(--pencil);
    font-weight: 300;

    font-size: 16px;
    text-align: right;
    padding-right: 8px;
}

.hour-content {
    display: block;
    position: relative;
    margin-top: -13px;
}

.hour-buffer {
    border-top: 2px solid var(--invisible);
    width: 10px;
}

.mouseHour {
    /* color: var(--dark); */
    color: var(--aqua);
    font-weight: 700;
    font-size: 20px;
}


.hour-content::selection {
    background: transparent;
}

.hour-content::-moz-selection {
    background: transparent;
}