.main-container {
height: 20px;
width: 20px;
border-radius: 50%;
border: 0.5px solid;

}

.main-container:not(:active){
    border-color: white;
}


.inner-container {
    /* width: 100%; */
}


.icon {
    /* width: 100%; */
}

.icon:not(:active){
    color: white;
    text-shadow: 0px 0.3px 0.3px hsl(0deg 0% 0% / 0.48);
}