.outer-flex{
    position: absolute;
    top: 0;
    
    height: var(--stage-size);
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    z-index: 3;
    background-color: black;
}

.main-container {
    /* max-width: 600px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    width: 100%;
    /* height: calc(min(100vw, 100%)); */
    height: var(--stage-size);

    background-color: black;
    position: absolute;
    top: 0;
    z-index: 20;
    pointer-events: all;
}

.main-container.options-1{
    justify-content: center;
}