.main-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    width: 100%;
}

.title-container {
    color: white;
    font-size: 28px;
    font-weight: bold;

    font-family: var(--sexy);
    font-style: italic;
    text-align: center;
}

.items-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    align-content: flex-start;
}

.note-container {
    color: white;
    text-align: center;
    margin-bottom: 24px;
    font-style: italic;
    padding: 0 24px;
}
