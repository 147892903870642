.kins-logo {
    font-family: var(--sexy);
    font-weight: 700;
    display: inline-block;
    cursor: pointer;
    /* font-style: italic; */
    position: relative;

}

.logo-letter {
    display: inline-block;
}


.footer {
    color: #f4efdb;
}

.light {
    color: var(--light);
    text-shadow: 3.2px 6px 6px hsl(0deg 0% 0% / 0.48);
}



.menu {
    font-size: 32px;
}

.logo-k.menu {
    /* transform: rotate(-15deg); */
}

.logo-i.menu {
    margin-left: -1px;
    /* transform: rotate(-5deg); */
}

.logo-n.menu {
    /* transform: rotate(5deg); */
}

.logo-s.menu {
    /* position: relative; */
    font-size: 26px;
    /* transform: rotate(15deg); */
    
    margin-left: -2px;
}









.mini{
    font-size: 32px;
    margin-left: -1px;
}

.kins-logo.mini {
    margin-left: 10px;
    width: 62px;
}

.logo-s.mini {
    font-size: 22px;
}


.tiny{
    font-size: 22px;
    /* margin-left: -1px; */
}

.kins-logo.tiny {    
    width:42px;
}

.logo-s.tiny {
    font-size: 17px;
}



.large{
    font-size: 200px;
    /* margin-left: -3px; */
}

.kins-logo.large {
    margin-top: -40px;
    
    margin-left: 10px;
    width: 400px;
}



.logo-k.large {
    position: relative;
    transform: rotate(-15deg);
    bottom: -20px;
}

.logo-i.large {
    margin-left: -8px;
    transform: rotate(-5deg);
}

.logo-n.large {
    transform: rotate(5deg);
    margin-left: 3px;
    z-index: 100;
}


.logo-s.large {
    position: relative;
    font-size: 170px;
    transform: rotate(15deg);
    bottom: -20px;
    margin-left: -10px;
}




.medium{
    font-size: 150px;
    /* margin-left: -3px; */
}

.kins-logo.medium {
    margin-top: -40px;
    
    margin-left: 10px;
    width: 280px;
}



.logo-k.medium {
    position: relative;
    transform: rotate(-15deg);
    bottom: -20px;
}

.logo-i.medium {
    margin-left: -8px;
    transform: rotate(-5deg);
}

.logo-n.medium {
    transform: rotate(5deg);
    margin-left: 3px;
    z-index: 100;
}


.logo-s.medium{
    position: relative;
    font-size: 128px;
    transform: rotate(15deg);
    bottom: -15px;
    margin-left: -12px;
}


.shiny {
    /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
    background: linear-gradient(-45deg, #757eff, #ca91dd, #f15591, #efa350, #fae270, #8fc2a0, #4fcbc9, #757eff, #ca91dd, #efa350, #fae270, #8fc2a0, #4fcbc9,  #757eff, #ca91dd, #f15591, #efa350, #fae270, #8fc2a0, #4fcbc9);
    background-size: 1000% 1000%;
    animation: gradient 60s ease infinite;


    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    text-shadow: none;
}

@keyframes gradient {
    0% {
        background-position: 100% 50%;
    }
    50% {
        background-position: 0% 50%;
    }
     100% {
        background-position: 100% 50%;
    }

}