* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

#root {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: #f8f9f9;
}

body {
    --light: #fffbe9;
    --dark: #363638;

    /* --red: #d7394f; */
    --red: #d34d5f;

    --orange: #efa350;
    --dark-orange: #aa6c2c;
    /* --yellow: #fae270; */
    /* --yellow: #ffe361; */
    --yellow: #fac85c;
    --light-yellow: #f8e691;

    /* --green:#6cc188; */
    --lime: #56c47b;
    --green: #8fc2a0;

    --blue: #8fbbb8;
    --blue: #757eff;
    --indigo: #006f92;
    --aqua: #4fcbc9;

    --purple: #ca91dd;
    /* --pink: #ffc4c1; */
    /* --pink:#ffaeaa; */
    /* --pink:#e73c7e; */
    --pink:#f15591;
    --peach: #f59995;

    --invisible: #e3e0d0;
    --pencil: #919088;
    --grey: #a19f9f;
    --brown: #5b3e33;
    --light-brown: #ac745f;

    /* background-color: var(--light); */
    background-color: black;

    --casual: 'Kalam', cursive;
    --sexy: 'Lobster Two', cursive;
    --boring: 'Lexend', sans-serif;
    --roboto: 'Roboto', sans-serif;

    font-family: var(--casual);

    margin: 0;
    height: 100%;

    /* --stage-size: min(100vw, 600px); */
    --stage-size: min(100vw, 412px);
    /* --thumbnail-size: min(44vw, 171px); */
    --thumbnail-size: calc(min(100vw, 412px) * 0.44);
    /* --thumbnail-gap: min(4vw, 16px); */
    --thumbnail-gap: calc(min(100vw, 412px) * 0.04);

    --browser-buttons-height: 155px; /* 75 for the button + 2 timex the top bar height */

    /* --top-bar-height: 54px;  */
    --top-bar-height: 40px;

    --font-color: white;
    /* --event-thumbnail-size: min(29vw, 140px); */
    --event-thumbnail-size: calc(min(100vw, 412px) * 0.29)
}

.outer-app-main-container {
    width: min(100vw, 412px);
    height: min(100vh, 850px);

    position: relative;
    background-color: black;
}

.app-main-container {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 13px;
    overflow: hidden;
    position: absolute;
    top: 0;    
}

.Black {
    background-color: black;
    border-color: black;
    color: black;
}

.Account {
    background-color: var(--blue);
    border-color: var(--blue);
    color: var(--blue);
}

.Events {
    background-color: var(--red);
    border-color: var(--red);
    color: var(--red);
}

.GoodTimes {
    background-color: var(--aqua);
    border-color: var(--aqua);
    color: var(--aqua);
}

.Invitations {
    background-color: var(--aqua);
    border-color: var(--aqua);
    color: var(--aqua);
}

.People {
    background-color: var(--purple);
    border-color: var(--purple);
    color: var(--purple);
}

.Places {
    background-color: var(--orange);
    border-color: var(--orange);
    color: var(--orange);
}

.Parties {
    background-color: var(--blue);
    border-color: var(--blue);
    color: var(--blue);
}

.HelpWanted {
    background-color: var(--green);
    border-color: var(--green);
    color: var(--green);
}

.HelpOffered {
    background-color: var(--peach);
    border-color: var(--peach);
    color: var(--peach);
}

.Groups {
    background-color: var(--light-brown);
    border-color: var(--light-brown);
    color: var(--light-brown);
}

.Cards {
    background-color: var(--yellow);
    border-color: var(--yellow);
    color: var(--yellow);
    /* background-color: var(--indigo);
    border-color: var(--indigo);
    color: var(--indigo); */
}

.grid-outline {
    border: 1px dashed var(--invisible);
}

.Danger {
    background-color: var(--red);
    border-color: var(--red);
    color: var(--red);
}

.Lime {
    background-color: var(--lime);
    border-color: var(--lime);
    color: var(--lime);
}

.Green {
    background-color: var(--green);
    border-color: var(--green);
    color: var(--green);
}

.Yellow {
    background-color: var(--yellow);
    border-color: var(--yellow);
    color: var(--yellow);
}

.Pink {
    background-color: var(--pink);
    border-color: var(--pink);
    color: var(--pink);
}

.Peach {
    background-color: var(--peach);
    border-color: var(--peach);
    color: var(--peach);
}

.Brown {
    background-color: var(--light-brown);
    border-color: var(--light-brown);
    color: var(--light-brown);
}

.White {
    background-color: white;
    border-color: white;
    color: white;
}

/* @media (max-width: 1000px) {
    .hide-on-mobile {
        display: none;
    }
} */

.hide-on-mobile {
    display: none;
}

/* @media (min-width: 1000px) {
    .hide-on-desktop {
        display: none;
    }
} */
