.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    position: relative;
    overflow: hidden;
    
}


.title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;

    top: 100px;
    left: 50px;
    z-index: 1;

    width: 300px;
    height: 120px;


    transform: rotate(-15deg);

    background-color: var(--aqua);
    border-radius: 50%;
    line-height: 80%;
    border: 3px solid white;

    box-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.45);
}


.top-title {
    margin-top: -10px;
    font-style: italic;
    font-weight: 700;
    font-family: var(--sexy);
    font-size: 30px;
    color: white;

    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
}




.event-title {
    
    font-style: italic;
    font-weight: 700;
    font-family: var(--sexy);
    font-size: 50px;
    color: white;
    /* max-width: 50%; */
    line-height: 80%;


    text-align: center;

    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
}

.message {
    position: absolute;
    top: 250px;
    z-index: 1;
    
    padding: 50px 35px;
    font-size: 22px;

    /* color: var(--light); */
    color: rgb(255, 246, 226);
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);

    line-height: 120%;

    /* mix-blend-mode: difference; */
    /* filter: invert(1) grayscale(1) contrast(9) */


    
    
}

.message-box{
    background-color:  rgba(0, 0, 0, 0.5);
    border-radius: 8px;   
    padding: 16px;
}


.event-element {
 color: var(--aqua);
}


.message-line {
    
    margin-bottom: 16px;
    
    
}



.logo-container {
    position: absolute;
    bottom: 50px;
    z-index: 1;
}



.image {
    /* width: 100%; */
    width: var(--currentViewWidth);
    height: var(--currentViewHeight);
    object-fit: cover;
    object-position: 46% 50%;
}

.go-back {
    position: absolute;
    left:0;
    top:0;
    color: white;
    -webkit-text-stroke-color: var(--aqua);
    -webkit-text-stroke-width: 1px;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);

    background-color:  rgba(0, 0, 0, 0.5);
    border-bottom-right-radius: 8px;
}