.about-container {
    position: absolute;
    top: 0;
    height: var(--currentViewHeight);
    width: var(--currentViewWidth);

    background-color: black;

}

