.main-container {
    width: 100%;

    display: flex;
    flex-direction: row;

    color: white;
    font-family: var(--casual);
    font-size: 24px;

    margin-top: -2px;
    font-weight: lighter;
}

.concept {
    width: 42%;
}

.quantity-container {
    width: 31%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    text-align: center;
}

.quantity {
    display: flex;
    flex-direction: row;
    margin-bottom: -4px;
}

.symbol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
}

.symbol.left {
    margin-right: 3px;
}

.symbol.right {
    margin-left: 3px;
}

.amount {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 27%;
    text-align: right;
}

.main-container.subtotal {
    margin-top: 3px;
    padding-top: 3px;
    border-top: 1px solid white;
    font-size: 18px;
    /* font-weight: 700; */
}

.main-container.taxes {
    font-size: 18px;
}

.main-container.gratuity {
    padding-bottom: 3px;
    border-bottom: 1px solid white;
    font-size: 18px;
}
.main-container.total {
    margin-top: 3px;
    padding-top: 3px;
    border-top: 1px solid white;
    font-size: 30px;
    font-weight: 500;
}

.money {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1px;
    font-size: 18px;
}