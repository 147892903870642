.main-container {
    position: absolute;
    top: 0;

    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    height: var(--currentViewHeight);
}

.application-title {
    margin-top: 3rem;
    font-size: 60px;
    font-style: italic;
    font-weight: 700;
    font-family: var(--sexy);

    -webkit-text-stroke-width: 1px;
    color: white;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
}


.image-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;    
}

.image {
    border-radius: 13px;
    box-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
}

.application-message {
    margin-top: 2rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 5px 10px;

    line-height: 130%;
    font-size: 14px;
    font-family: var(--roboto);
    max-width: 80%;
}

.error-message {
    color: var(--red);
    text-align: center;
    margin-top: 1rem;
}


.learn-about-kins {
    color: white;
    cursor: pointer;
    font-size: 20px;
    margin-top: 2rem;
}

.learn-about-kins:hover {
    font-size: 32px;
    font-weight: 700;
}
