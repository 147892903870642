.main-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 32px;
}

.inner-container {
    display: flex;
    flex-direction: row;
    width: 200px;
    position: relative;
}

.text-container {
    font-size: 32px;
    z-index: 6;
    margin-right: -25px;
    margin-top: 27px;
    color: #eeb7a9;
    font-family: var(--casual);
}

.loading-image {
    height: 120px;
    z-index: 5;
    max-width: 400px;

    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
