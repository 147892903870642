.absolute-container{
    display: flex;
    justify-content: center;
    top: calc(var(--stage-size) +  var(--top-bar-height) * 2);
    
    position: absolute;
    bottom: 0;
    width: 100%;
    /* z-index: 0; */
    pointer-events: all;
}

.absolute-container.display-none{
    display: none;
}

.button-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    cursor: pointer;

    border-radius: 8px;
    border: 4px solid;

    background-color: black;

    padding: 0;
}

.button-container:active {
    background-color: transparent;
}

.button-container:disabled {
    background-color: var(--pencil);
    border-color: var(--invisible);
    color: var(--invisible);
    cursor: auto;
}

.button-container:disabled:not(:active) {
    color: var(--invisible);
}

.button-container:not(:active) {
    color: var(--font-color);
    border-color: rgb(255, 255, 255, 0.2);
    border-radius: 5px;

    /* border-color: black; */
}

.text-container {
    position: relative;
    display: flex;
    justify-content: center;
    font-family: var(--sexy);
    font-size: 30px;
    font-style: italic;
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
    width: 100%;
    height: 100%;

    background-color: rgb(255, 255, 255, 0.1);
    padding: 0;
}

.shiny-container {
    position: absolute;
    top: 0px;
}

.white-container {
    position: absolute;
    top: 0px;
}

.shiny {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab, white);
    background-size: 600% 600%;
    animation: gradient 5s ease infinite;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
}

.shiny:active{
   -webkit-text-fill-color:black;
}

.shiny.dissabled {
    /* background: var(--invisible); */
    color: var(--invisible);
    -webkit-text-fill-color: var(--invisible);
    
}

.shiny-base {
    height: 60px;
    width: 300px;
    padding: 4px;
    border-radius: 8px;

    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab, white);
    background-size: 600% 600%;
    animation: gradient 5s ease infinite;
    box-shadow: 0px 3.2px 3.2px hsl(0deg 0% 0% / 0.45);
}

.shiny-base.dissabled {
    background: var(--invisible);
}

.black-background {
    height: 100%;
    width: 100%;
    background-color: black;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
