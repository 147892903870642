.outer-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.main-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;

    border-radius: 8px;
    margin-bottom: 16px;

    padding: 8px 16px;
    pointer-events: all;

    box-shadow: 0px 3.2px 3.2px hsl(0deg 0% 0% / 0.45);
    cursor: pointer;
}

.thumnails-container {
    width: calc(100% - 82px);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.and-more-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    color: white;
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 48%);
    font-size: 24px;
    font-family: var(--roboto);

    height: 100%;
    width: 32px;
    margin-left: 8px;
    /* padding-left: 20px; */
}

.and-more-text {
    margin-top: -3px;
}
