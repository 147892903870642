.main-container {
    pointer-events: none;

    visibility: hidden;
    position: absolute;
    bottom: -0px;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    --curtain-size: calc(var(--topics-height) - 8px);
}

.topics-curtain {
    position: absolute;
    bottom: 0;

    height: var(--curtain-size);
    width: calc(100%);
    /* width: calc(100% - 16px); */

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    box-shadow: 0px -0px 5px hsl(0deg 0% 0% / 0.45);
    overflow: hidden;

    /* border: 4px solid rgb(255, 255, 255, 0.2); */
    border-bottom: 0;
}

.curtain-color {
    height: 100%;
    width: calc(100%);
    /* background-color: rgb(255, 255, 255, 0.15); */
}

.main-container.opened {
    /* Invisible by default */
}

.main-container.closed {
    visibility: visible;
}

.main-container.close {
    visibility: visible;
    animation: closeCurtain var(--close-speed, 800);
}

.main-container.open {
    animation: openCurtain var(--open-speed, 800);
}

@keyframes closeCurtain {
    0% {
        visibility: visible;
        bottom: calc(-1 * var(--curtain-size));
    }

    100% {
        bottom: 0;
        
    }
}

@keyframes openCurtain {
    0% {
        visibility: visible;
        bottom: 0;
        
    }

    100% {
        visibility: visible;
        bottom: calc(-1 * var(--curtain-size));
    }
}
