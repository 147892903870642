.main-container {
    visibility: hidden;
    max-width: 50px;
    height: 50px;
    /*margin-left: -125px;*/
    margin: auto;
    background-color: rgb(1, 1, 1, 0.5);
    color: var(--light);
    text-align: center;
    border-radius: 50px;

    position: absolute;
    z-index: 20;
    top: 80px;
    left: 0;
    right: 0;

    font-size: 20px;
    white-space: nowrap;
}

.main-container .icon-container {
    width: 50px;
    height: 50px;

    float: left;

    box-sizing: border-box;

    /* background-color: rgb(1, 1, 1, 0.5); */
}

.text-container,
.icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
}

.icon {
    display: block;
}

.main-container .text-container {
    padding-right: 35px;

    overflow: hidden;
    white-space: nowrap;
    height: 100%;
}

.text-content {
    visibility: hidden;
    display: block;
    opacity: 0;
}

.main-container.show {
    -webkit-animation: fadein 0.3s, expand 0.3s 0.3s, stay 1s 0.6s, shrink 0.3s 1.6s,
        fadeout 0.3s 1.9s;
    animation: fadein 0.3s, expand 0.3s 0.3s, stay 1s 0.6s, shrink 0.3s 1.6s, fadeout 0.3s 1.9s;
}

.show .text-content {
    -webkit-animation: fadein 0.3s 0.3s, stay 1s 0.6s;
    animation: fadein 0.3s 0.3s, stay 1s 0.6s;
}

@-webkit-keyframes fadein {
    from {
        visibility: visible;
        top: 0;
        opacity: 0;
    }
    to {
        visibility: visible;
        top: 80px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        visibility: visible;
        top: 0;
        opacity: 0;
    }
    to {
        visibility: visible;
        top: 80px;
        opacity: 1;
    }
}

@-webkit-keyframes expand {
    from {
        visibility: visible;
        min-width: 50px;
    }
    to {
        visibility: visible;
        min-width: 350px;
    }
}

@keyframes expand {
    from {
        visibility: visible;
        min-width: 50px;
    }
    to {
        visibility: visible;
        min-width: 350px;
    }
}
@-webkit-keyframes stay {
    from {
        visibility: visible;
        min-width: 350px;
        opacity: 1;
    }
    to {
        visibility: visible;
        min-width: 350px;
        opacity: 1;
    }
}

@keyframes stay {
    from {
        visibility: visible;
        min-width: 350px;
        opacity: 1;
    }
    to {
        visibility: visible;
        min-width: 350px;
        opacity: 1;
    }
}
@-webkit-keyframes shrink {
    from {
        visibility: visible;
        min-width: 350px;
    }
    to {
        visibility: visible;
        min-width: 50px;
    }
}

@keyframes shrink {
    from {
        visibility: visible;
        min-width: 350px;
    }
    to {
        visibility: visible;
        min-width: 50px;
    }
}

@-webkit-keyframes fadeout {
    from {
        visibility: visible;
        top: 80px;
        opacity: 1;
    }
    to {
        visibility: visible;
        top: 120px;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        visibility: visible;
        top: 80px;
        opacity: 1;
    }
    to {
        visibility: visible;
        top: 120px;
        opacity: 0;
    }
}
