.light-box {
    height: 100%;
    width: 100%;
    
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;


    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;

    padding: min(4vw, 16px);

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    position: relative;
    pointer-events: all;
}