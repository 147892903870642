.main-container {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: black;
    
}

.inner-container {}


.text-container {}
