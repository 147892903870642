.outer-container {
    --title-thumbnail-size:120px;
    position: relative;
    width: var(--title-thumbnail-size);
    height: var(--title-thumbnail-size);
}

.main-container {
    position: relative;

    height: 100%;
    width: 100%;

    border: 2px solid;
    border-radius: 8px;
    cursor: pointer;
    
    /* box-shadow: 0.8px 0.8px 0.8px hsl(0deg 0% 0% / 0.48); */
}

.thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    padding: 0;
    display: block;
}

.item-off {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: var(--dark);
    width: 100%;
    height: 100%;
    border-radius: 6px;
}