.main-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;
    width: 100%;
}

.title-container {
    color: white;
    font-size: 36px;
    font-weight: 700;
    
    /* margin-left: 24px; */
    margin-bottom: 24px;

    font-family: var(--sexy);
    font-style: italic;
    text-align: center;
}

.categories-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    align-content: flex-start;
}