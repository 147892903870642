.button-frame {
    display: flex;
    background-color: white;
    border: 3px solid var(--dark);
    height: 60px;
    border-radius: 5px;
    cursor: pointer;
   
}

.button-frame:hover{
    box-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.45);
}


.button-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 55px;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--dark);
}

.text-container.pressed { 
    background-color: var(--dark);
    color: white;
}

.button-text {
    /* text-align: center; */
    padding-left: 20px;
    color: inherit;

    /* font-family: 'Roboto', sans-serif; */
    font-weight: 700;
    font-size: 18px;
    width: 205px;
    margin-bottom: -3px;
}
