.input-container {
    margin-top: 1rem;
    border: 3px solid var(--dark);
    height: 60px;
    border-radius: 5px;
    display: flex;

}

.input-container:hover {
    box-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.45);
}

.input-icon-container {
    width: 44.48px;
    background-color: var(--dark);
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-icon {
    color: white;
    font-size: 24px;
}

.input-text {
    cursor: pointer;
    width: calc(100% - 44.48px - 70px);
    border: none;
    font-family: inherit;
    font-size: 16px;
    font-family: var(--roboto);

}

.input-text:focus {
    outline: none;
}

.country-code {
    cursor: pointer;
    width: 70px;
    border: none;
    font-family: inherit;
    font-size: 16px;

    padding-left: 5px;
    font-family: var(--roboto);
}
