.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;    
    padding-bottom: 40px;
}


.title-container {
    margin-top: 32px;
    margin-bottom: 16px;
    color: white;
    font-size: 30px;
    font-weight: 700;

    font-family: var(--roboto);
    text-align: left;
    width: 100%;
    padding-left: 5vw;

    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
}