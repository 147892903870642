.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(var(--currentViewHeight) - var(--top-bar-height));
    overflow-y: auto;
    overflow: hidden;
    position: absolute;
    bottom: 0vh;
}

.scroll-area{
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}



.photo {
    width: 100%;
    border-bottom: 0.5px solid white;
    display: block;
}