.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    pointer-events: none;
}

.tap-play {
    color: white;
    font-weight: bold;
    font-style: italic;
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 40px;
    z-index: 4;
}

.tap-play-text {
    font-family: var(--sexy);
    font-size: 42px;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
    padding: 5px 30px 13px 30px;
    background-color: var(--aqua);
    box-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.45);
    border: 2px solid white;
    border-radius: 50%;

}
