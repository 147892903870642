.input-container {
    
    border-radius: 5px;
    display: flex;
    background-color: transparent;
    padding-right: 16px;
    padding: 8px;
}


.input-icon-container {
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
}

.input-icon {
    font-size: 24px;
    background-color: transparent;
}


.input-text{
    font-family: var(--roboto);

    cursor: pointer;
    width: calc(100% - 25px);
    border: none;
    /* font-family: inherit; */
    font-size: 16px;

    line-height: 140%;

    /* height: 36px; */
    
    background-color: transparent;
    /* border-bottom: 2px solid var(--orange); */

    resize: none;
    color: white;
}


.input-text.long{
    height: 60px;
}


.input-text:focus {
    outline: none;
}
