.main-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    height: 100%;
    width: 100%;
    z-index: 2;
    position: absolute;
    pointer-events: none;
}

.inner-container {}


.text-container {}
