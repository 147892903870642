.main-container {
    height: calc(var(--currentViewHeight) - var(--top-bar-height));
    width: 100%;
    background-color: var(--dark);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.max-size-inner {    
    height: 100%;
    width: 100%;
    position: relative;
}
