.main-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2vh;
    width: 100%;
}

.title-container {
    color: white;
    font-size: 24px;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
}

.items-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    align-content: flex-start;
}

.note-container {
    color: white;
    font-style: italic;
    margin-bottom: 1vh;
}