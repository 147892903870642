.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: white;

    z-index: 1;
    width: 50px;
    height: 60px;
}
