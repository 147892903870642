.media-box {
    position: relative;

    height: var(--stage-size);
    width: 100%;
}

.media-box.vertical-mode{
    height: 100%;
    max-width: 600px;
}


.play-pause-button {
    pointer-events: all;
    /* background-color: red; */
    position: absolute;
    width: 100%;
    height: 85%;
    top: 0%;
    z-index: 1;
}
