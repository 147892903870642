.main-container {
    position: absolute;
    bottom: 10%;

    display: flex;
    flex-direction: column;
    cursor: pointer;

    font-size: 20px;
    color: white;
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
}


.main-container:hover {
    font-size: 24px;
    /* font-weight: 700; */    
}
