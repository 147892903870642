.main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    align-items: center;
    justify-content: center;
    font-family: var(--casual);

    background-color: var(--dark);
    color: white;

    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 48%);
}

.weekday-container {
    font-size: 24px;
    font-weight: bold;
    position: relative;
    height: 38px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.weekday-container.large {
    font-size: 48px;
    height: 78px;
}

.time-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.time-row.large {
    justify-content: space-between;
    width: 210px;
}

.time-container {
    margin-top: -10px;
    font-size: 20px;
}

.time-container.large {
    margin-top: -18px;
    font-size: 36px;
}

.date-box {
    margin-top: 10px;

    display: flex;
    flex-direction: column;
    /* border: 3px solid black; */
    border: 3px solid var(--aqua);
    border-radius: 8px;
    align-items: center;
    width: 60px;
    height: 60px;
    box-sizing: content-box;
}

.date-box.large {
    margin-top: 16px;
    width: 80px;
    height: 80px;
    border: 5px solid var(--aqua);

    border-radius: 13px;
}

.date-month {
    display: flex;
    font-size: 16px;
    font-weight: 700;
    height: 25px;
    /* background-color: black; */
    background-color: var(--aqua);
    width: 100%;
    color: white;
    text-align: center;

    justify-content: center;
    align-items: center;
}

.date-month.large {
    height: 34px;
    font-size: 28px;
}

.date-day {
    background-color: white;
    color: var(--aqua);

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 100%;
    height: 100%;

    padding: 8px;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /* text-shadow: none; */
}

.date-day.large {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.date-day-text {
    height: 20px;
    font-size: 22px;
    margin-top: -10px;
}

.date-day-text.large {
    font-size: 40px;
    margin-top: -36px;
}

.shiny {
    text-shadow: none;
    position: absolute;
    top: 0;

    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);

    background-size: 600% 600%;
    animation: gradient 5s ease infinite;
    font-weight: 700;

    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.weekday-white {
    position: absolute;
    top: 0;
}
