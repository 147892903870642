.main-container {
    visibility: hidden;
    width: 100%;
    height: var(--currentViewHeight);
    /* height: calc(var(--currentViewHeight) - 5vh); */
    z-index: 30;
    position: absolute;
    /* bottom: 5vh; */
    bottom: 0vh;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    box-shadow: 0px 2px 2px hsl(0deg 0% 0% / 0.45);

    overflow: hidden;
    pointer-events: all;
}

.max-size-outer {
    height: 100%;
    width: 100%;
    background-color: var(--dark);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.max-size-inner {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 3vh;
}

.search-area {
    height: 100%;
    width: 100%;
    max-width: 500px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.main-container.show {
    visibility: visible;
    -webkit-animation: pullIn 0.3s;
    animation: pullIn 0.3s;
}

.main-container.hide {
    -webkit-animation: pushOut 0.3s;
    animation: pushOut 0.3s;
}

@-webkit-keyframes pullIn {
    from {
        right: calc(var(--currentViewWidth) * -1);
    }
    to {
        right: 0;
    }
}

@keyframes pullIn {
    from {
        right: calc(var(--currentViewWidth) * -1);
    }
    to {
        right: 0;
    }
}

@-webkit-keyframes pushOut {
    from {
        visibility: visible;
        right: 0;
    }
    to {
        right: calc(var(--currentViewWidth) * -1);
    }
}

@keyframes pushOut {
    from {
        visibility: visible;
        right: 0;
    }
    to {
        visibility: visible;
        right: calc(var(--currentViewWidth) * -1);
    }
}
