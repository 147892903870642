.main-container {
    display: flex;
    
    font-family: var(--roboto);
}

.inner-container {
}

.text-container {
}

.timer-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4px;
}

.time-dots {
    font-size: 32px;
    padding-top: 2px;
}

.time-value {
    font-size: 32px;
}

.time-units {
    font-size: 12px;
    color: var(--aqua);
}

.happening-in {
    font-family: var(--sexy);
    font-style: italic;
    font-size: 24px;
    color: var(--aqua);
}