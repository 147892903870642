.main-container {
    z-index: 25;
    position: absolute;
    padding: 16px;
    right: 0;
    
    color: white;


    -webkit-text-stroke-width: 1px;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);
    pointer-events: all;
    cursor: pointer;
}