.main-container {
    position: absolute;
    z-index: 2;
    background-color: rgba(1, 1, 1, 0.5);
    width: 100%;
    height: 100%;
    border-radius: 8px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.text-container {
    color: white;
}