.main-container {
    color: white;
    text-shadow: 0.4px 0.8px 0.8px hsl(0deg 0% 0% / 0.48);
    
    /* padding: 0px 5px;
    background-color: var(--green);
    border: 1px solid white;
    border-radius: 5px; */
    margin-right: 16px;
    cursor: pointer;
}

.inner-container {
    display: flex;
    flex-direction: row;
}

.text-container {
    font-family: var(--sexy);
    font-size: 20px;
    padding-left: 5px;
}