.main-container {
    display: flex;
    flex-direction: column;
}


.text-container {
    font-family: var(--sexy);
    font-size: 32px;
    font-style: italic;
    cursor: pointer;
    /* margin-top: -3px; */
    font-weight: 700;
    padding-left: 24px;
}