.photo-carousel {
    position: relative;
    height: 100%;
    width: 100%;
}

.current-photo {
    position: absolute;
    visibility: hidden;
    z-index: 1;
}

.current-photo.fade-in {
    animation: fadePhotoIn 3000ms;
}

@keyframes fadePhotoIn {
    0% {
        visibility: visible;
        opacity: 0%;
    }

    50% {
        visibility: visible;
        opacity: 100%;
    }

    100% {
        visibility: visible;
        opacity: 100%;
    }
}

.previous-photo {
    position: absolute;
    z-index: 0;
}

.current-photo,
.previous-photo {
    width: 100%;
    height: 100%;    
    object-fit: cover;
}
