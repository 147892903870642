.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.inner-container {}


.text-container {}

.preview-container {
    margin-top: 40px;    
}

.timer-container {
    text-align: center;
    margin-top: 40px;
}

.image-container {
    position: absolute;
    top: 40px;
    height: calc(var(--currentViewHeight) - 40px);
    z-index: -1;
}


.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 46% 50%;
}