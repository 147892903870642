.logo-container {
    position: relative;
    width: 50%;
    height: 100%;
    padding-left: 24px;
    margin-bottom: -3px;
}

.shiny-container {
    position: absolute;
}

.white-container {
    position: absolute;
}

.options-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 16px;
    color: white;
    max-width: 180px;
    margin-bottom: -2px;
}
