.outer-flex {
    width: 100%;
    height: calc(var(--currentViewHeight) - var(--top-bar-height));
    bottom: 0;

    position: absolute;


    display: flex;
    flex-direction: column;
    align-items: center;

    

    font-family: var(--roboto);
}

.main-container {
    max-width: 750px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    padding: min(4vw, 16px);

    overflow-y: auto;
}