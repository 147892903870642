.week-box {
    display: flex;
    flex-direction: row;
}

.day-box {
    display: flex;
    width: 40px;
    height: 40px;

    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    margin-bottom: 3px;
    cursor: pointer;

    background-color: var(--light);
}

.day-box.has-passed {
    background-color: var(--dark);
    color: grey;
    border: none;
}


.day-box.has-passed.event-day {
    color: var(--aqua);
    border: 1px var(--aqua) solid;

}

.day-box.is-closed {
    background-color: grey;
    /* color: white; */
}

.day-box.day-box.is-closed.outsider-day {
    color: #929292;
}

.day-content {
    margin-bottom: -3px;
}

.outsider-day {
    color: var(--invisible);    
}

.event-day {
    background-color: var(--aqua);
    color: white;
    /* border: 1px white solid; */
}



.week-days {
    display: flex;
    flex-direction: row;
    color: white;
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 48%);
}

.week-day {
    display: flex;
    width: 40px;
    height: 30px;
    font-weight: 700;
    justify-content: center;
    align-items: center;

    margin-left: 3px;
}
