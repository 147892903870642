.vertical-shadow {
    text-shadow: 0px 0.8px 0.8px hsl(0deg 0% 0% / 48%);
}

.options-container {
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 10px;
}