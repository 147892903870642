.outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    height: 100%;
    width: 100%;

    background-color: var(--dark);
}

.main-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;

    padding: 24px 24px 24px 24px;
    max-width: 340px;

    color: white;
    font-family: var(--casual);
    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);

}

.title {
    font-size: 44px;
    font-style: italic;
    font-weight: 700;
    font-family: var(--sexy);
    
    -webkit-text-stroke-width: 1px;
    text-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.48);
    margin-bottom: 24px;
}

.day-hours-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 22px;
    margin-bottom: 5px;
}