.main-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;

    color: white;
}

.inner-container {
}

.text-container {
}
