.main-container {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: row;
    color: white;
    margin-bottom: 16px;
    justify-content: space-between;
    /* font-family: var(--casual); */
}

.name-and-ingredients {
    display: flex;
    flex-direction: column;

    margin-left: 16px;
    padding: 8px;
}

.in-photo {
    background-color: rgba(1, 1, 1, 0.5);
    border-radius: 8px;
}

.name-and-ingredients.in-photo {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 16px;
}

.dish-name {
    font-size: 18px;
    font-weight: bold;
}

.dish-ingredients {
    font-size: 16px;
    font-weight: 300;
}

.dish-price {
    font-size: 18px;
    font-weight: bold;
    /* color: var(--red); */
}

.price-and-extras {
    margin-right: 16px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.price-and-extras.in-photo {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 16px;
}

.extra-ingredients {
    font-size: 16px;
    font-weight: 300;
    white-space: nowrap;
    /* font-weight: bold; */
    /* color: var(--red); */
}

.menu-photo {
    width: 100%;
    border-top: 0.5px solid white;
    border-bottom: 0.5px solid white;
    display: block;
}
