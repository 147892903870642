.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.desktop,
.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: var(--currentViewWidth);
    height: var(--currentViewHeight);
}



.welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 15%;
    font-family: var(--sexy);
    font-style: italic;
    color: var(--light);
    text-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.48);
    line-height: 300%;
    font-weight: 700;
}


.mobile .welcome-container {
    top: 10%;
}


.desktop .welcome {
    font-size: 60px;
    margin-left: -10px;
}


.mobile .welcome {
    font-size: 40px;
}



.kins-logo {
    position: absolute;
    top: 20%;
}

.auth-choice {
    position: absolute;
    /* z-index: 10; */
}


.desktop .auth-choice {
    width: 230px;
    top: 30px;
    right: 50px;
}


.mobile .auth-choice {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 23%;
    width: 270px
}


.sexy-logo-container {
    position: absolute;
    top: 20%;
    z-index: 10;
}


.mobile .sexy-logo-container {
    top: 16%;
    margin-left: -8px;
}


.learn-about-kins {
    color: white;
    cursor: pointer;
    font-size: 20px;
}


.desktop .learn-about-kins {
    position: absolute;
    bottom: 10%;
}

.mobile .learn-about-kins{
    position:relative;
    margin-top: 60px;
}


.learn-about-kins:hover {
    font-size: 32px;
    font-weight: 700;
}
