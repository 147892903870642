.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;

}

.modal-title {
    font-size: 40px;
    font-family: var(--sexy);
    font-style: italic;
    margin-top: 30vh;
}

.choice-container{
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: space-between;

    width: 280px;

    margin-top: 24px;
    margin-bottom: 24px;
}


.inner-container {}


.text-container {
    font-family: var(--roboto);
    /* font-weight: lighter; */
}
