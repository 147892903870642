.main-container {
    position: absolute;
    top: 0;

    height: var(--stage-size);
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--dark);

    color: white;
    font-family: var(--sexy);
}

.stage-image {
    position: absolute;
    top: 0;
    display: block;
    height: var(--stage-size);
    width: 100%;
    object-fit: cover;
    
}

.message {
    font-size: 40px;
    background-color: rgba(0,0,0,.6);
    border-radius: 8px;
    padding: 8px;
    z-index: 1;
}