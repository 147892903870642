.main-container{
    display: flex;
    flex-direction: row;
    position: relative;

    text-shadow: 0.8px 1.6px 1.6px hsl(0deg 0% 0% / 0.48);

    box-shadow: 1.2px 2.4px 2.4px hsl(0deg 0% 0% / 0.45);
    border-radius: 8px;
}

.detail-button {
    position: absolute;
    top: 3px;
    right: 0;
    color: white;
}