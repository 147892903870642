.outer-container {
    position: relative;
    width: var(--event-thumbnail-size);
    height: var(--event-thumbnail-size);
}

.main-container {
    position: relative;

    height: 100%;
    width: 100%;

    border: 3px solid;
    border-radius: 8px;
}

.thumbnail-image {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 0;
    display: block;
    object-fit: cover;
}

.item-off {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}

.name-tag {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    padding-top: 2px;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 4px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 8px;
}

.text-container {
    color: white;
    font-family: var(--sexy);
    font-style: italic;
    text-shadow: 0.4px 0.4px 0.4px hsl(0deg 0% 0% / 48%);

}