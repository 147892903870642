.outer-flex {
    --browser-space-left: calc(
        var(--currentViewHeight) - var(--stage-size) - var(--top-bar-height)
    );

    --topics-height: calc(
        var(--currentViewHeight) - var(--stage-size) - var(--top-bar-height) -
            var(--browser-buttons-height)
    );
    --cards-topics-height: calc(
        var(--currentViewHeight) - var(--stage-size) - var(--top-bar-height) * 2 - 20px
    );

    --invitation-card-topics-height: calc(var(--currentViewHeight) - var(--stage-size) - 8px);

    width: 100%;
    /* height: calc(var(--currentViewHeight) - var(--top-bar-height)); */

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    font-family: var(--roboto);
    pointer-events: none;
}

.main-container {
    height: 100%;
    width: 100%;
    /* max-width: 600px; */

    display: flex;
    flex-direction: column;
}
